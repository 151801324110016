<template>
  <b-row class="h-100">
    <b-col
      cols="12"
      class="h-100"
    >
      <b-card
        border-variant="primary"
        :header="headerTitle"
        header-bg-variant="primary"
        header-text-variant="white"
        class="card__setting"
      >
        <fieldset>
          <b-card-text class="pt-1">
            <b-row>
              <!-- sport -->
              <b-col
                cols="12"
                sm="6"
              >
                <b-row class="h-100">
                  <b-col
                    cols="12"
                    class="h-100"
                  >
                    <b-card
                      border-variant="primary"
                      :header="`${headerTitle} Sport`"
                      header-bg-variant="primary"
                      header-text-variant="white"
                      class="card__setting"
                    >
                      <b-card-text class="pt-1">
                        <b-row>
                          <b-col cols="12">
                            <SettingComponentSportM8
                              :header-title="`${headerTitle} Sport`"
                              :setting-key="`${settingKey}`"
                              :setting-key-enabled="`${settingKey}`"
                              :is-hide-checkbox-enabled="false"
                            />
                          </b-col>
                          <b-col cols="12">
                            <SettingComponentSportM8
                              :header-title="`${headerTitle} Live`"
                              :setting-key="`${settingKey}live`"
                              :setting-key-enabled="`${settingKey}`"
                              :is-hide-checkbox-enabled="true"
                            />
                          </b-col>
                        </b-row>

                        <!-- commission, limit -->
                        <b-row>
                          <b-col cols="12">
                            <div class="divider">
                              <hr>
                            </div>
                            <b-row class="pb-4">
                              <!-- commission -->
                              <b-col
                                v-if="getInitSettingCommissionWithKey"
                                md="6"
                                sm="12"
                              >
                                <b-card
                                  no-body
                                  class="border mt-1"
                                >
                                  <b-card-header class="p-1">
                                    <b-card-title class="font-medium-2">
                                      <feather-icon
                                        icon="PercentIcon"
                                        size="18"
                                      />
                                      <span class="align-middle ml-50">{{
                                        $t("CommissionSetting")
                                      }}</span>
                                    </b-card-title>
                                  </b-card-header>

                                  <b-card-body v-if="settingIsMember">
                                    <b-row v-if="allowChooseHdpOuOeType">
                                      <b-col xs="12">
                                        <b-row
                                          class="
                                            align-items-center
                                            p-1
                                            no-gutters
                                          "
                                        >
                                          <b-col cols="4">
                                            {{ $t("CommissionType") }}
                                          </b-col>
                                          <b-col>
                                            <v-select
                                              :value="memberHdpOuOeType"
                                              :options="optionsCommissionType"
                                              :clearable="false"
                                              label="title"
                                              :reduce="(option) => option.data"
                                              @input="
                                                onMemberUpdateCommissionType
                                              "
                                            />
                                          </b-col>
                                        </b-row>
                                      </b-col>
                                    </b-row>
                                    <b-row
                                      v-for="commissionKey in commissionMemberKeysSportWithLive"
                                      :key="commissionKey"
                                    >
                                      <AccountSettingSportM8Commission
                                        :setting-commission="settingCommission"
                                        :init-setting-commission="
                                          getInitSettingCommissionWithKey
                                        "
                                        :commission-key="commissionKey"
                                        :setting-key="settingKey"
                                        :hdp-ou-oe-init="hdpOuOeInit"
                                        :init-commission-frontend="
                                          initCommissionFrontend
                                        "
                                        :setting-is-member="settingIsMember"
                                        @onUpdateCommission="onUpdateCommission"
                                      />
                                    </b-row>
                                  </b-card-body>
                                  <b-card-body v-else>
                                    <b-row
                                      v-for="commissionKey in commissionAgentKeysSportWithLive"
                                      :key="commissionKey"
                                    >
                                      <AccountSettingSportM8Commission
                                        :setting-commission="settingCommission"
                                        :init-setting-commission="
                                          getInitSettingCommissionWithKey
                                        "
                                        :commission-key="commissionKey"
                                        :setting-key="settingKey"
                                        :hdp-ou-oe-init="hdpOuOeInit"
                                        :init-commission-frontend="
                                          initCommissionFrontend
                                        "
                                        :setting-is-member="settingIsMember"
                                        @onUpdateCommission="onUpdateCommission"
                                      />
                                    </b-row>
                                  </b-card-body>
                                </b-card>
                              </b-col>

                              <!-- limit setting -->
                              <b-col
                                v-if="getInitSettingLimitWithKey"
                                md="6"
                                sm="12"
                              >
                                <b-card
                                  no-body
                                  class="border mt-1"
                                >
                                  <b-card-header class="p-1">
                                    <b-card-title class="font-medium-2">
                                      <feather-icon
                                        icon="SlashIcon"
                                        size="18"
                                      />
                                      <span class="align-middle ml-50">{{
                                        $t("LimitSetting")
                                      }}</span>
                                    </b-card-title>
                                  </b-card-header>
                                  <validation-observer ref="formLimitSetting">
                                    <b-form>

                                      <b-row
                                        v-for="key in getKeysLimitSportWithLive"
                                        :key="key"
                                      >
                                        <AccountSettingSportM8LimitSetting
                                          :is-all-empty="checkisAllEmptyLimitSetting({settingLimit, settingKey})"
                                          :setting-limit="settingLimit"
                                          :init-setting-limit="
                                            getInitSettingLimitWithKey
                                          "
                                          :limit-key="key"
                                          :setting-key="settingKey"
                                          :upline-init-setting-limit="
                                            uplineInitSettingLimit
                                          "
                                          @onUpdateLimitSetting="
                                            onUpdateLimitSetting
                                          "
                                        />
                                      </b-row>
                                    </b-form>
                                  </validation-observer>
                                </b-card>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-card-text>
                      <slot />
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <!-- end sport -->

              <!-- live -->
              <b-col
                cols="12"
                sm="6"
              >
                <b-row class="h-100">
                  <b-col
                    cols="12"
                    class="h-100"
                  >
                    <b-card
                      border-variant="primary"
                      :header="`${headerTitle} Step`"
                      header-bg-variant="primary"
                      header-text-variant="white"
                      class="card__setting"
                    >
                      <b-card-text class="pt-1">
                        <b-row>
                          <b-col cols="12">
                            <SettingComponentSportM8
                              :header-title="`${headerTitle} Step`"
                              :setting-key="`${settingKey}step`"
                              :setting-key-enabled="`${settingKey}`"
                              :is-hide-checkbox-enabled="true"
                            />
                          </b-col>
                        </b-row>

                        <!-- commission, limit -->
                        <b-row>
                          <b-col cols="12">
                            <div class="divider">
                              <hr>
                            </div>
                            <b-row class="pb-4">
                              <!-- commission -->
                              <b-col
                                v-if="getInitSettingCommissionWithKey"
                                md="6"
                                sm="12"
                              >
                                <b-card
                                  no-body
                                  class="border mt-1"
                                >
                                  <b-card-header class="p-1">
                                    <b-card-title class="font-medium-2">
                                      <feather-icon
                                        icon="PercentIcon"
                                        size="18"
                                      />
                                      <span class="align-middle ml-50">{{
                                        $t("CommissionSetting")
                                      }}</span>
                                    </b-card-title>
                                  </b-card-header>

                                  <b-card-body v-if="settingIsMember">
                                    <b-row
                                      v-for="commissionKey in commissionMemberKeysSportStep"
                                      :key="commissionKey"
                                    >
                                      <AccountSettingSportM8Commission
                                        :setting-commission="settingCommission"
                                        :init-setting-commission="
                                          getInitSettingCommissionWithKey
                                        "
                                        :commission-key="commissionKey"
                                        :setting-key="settingKey"
                                        :hdp-ou-oe-init="hdpOuOeInit"
                                        :init-commission-frontend="
                                          initCommissionFrontend
                                        "
                                        :setting-is-member="settingIsMember"
                                        @onUpdateCommission="onUpdateCommission"
                                      />
                                    </b-row>
                                  </b-card-body>
                                  <b-card-body v-else>
                                    <b-row
                                      v-for="commissionKey in commissionAgentKeysSportStep"
                                      :key="commissionKey"
                                    >
                                      <AccountSettingSportM8Commission
                                        :setting-commission="settingCommission"
                                        :init-setting-commission="
                                          getInitSettingCommissionWithKey
                                        "
                                        :commission-key="commissionKey"
                                        :setting-key="settingKey"
                                        :hdp-ou-oe-init="hdpOuOeInit"
                                        :init-commission-frontend="
                                          initCommissionFrontend
                                        "
                                        :setting-is-member="settingIsMember"
                                        @onUpdateCommission="onUpdateCommission"
                                      />
                                    </b-row>
                                  </b-card-body>
                                </b-card>
                              </b-col>

                              <!-- limit setting -->
                              <b-col
                                v-if="getInitSettingLimitWithKey"
                                md="6"
                                sm="12"
                              >
                                <b-card
                                  no-body
                                  class="border mt-1"
                                >
                                  <b-card-header class="p-1">
                                    <b-card-title class="font-medium-2">
                                      <feather-icon
                                        icon="SlashIcon"
                                        size="18"
                                      />
                                      <span class="align-middle ml-50">{{
                                        $t("LimitSetting")
                                      }}</span>
                                    </b-card-title>
                                  </b-card-header>
                                  <validation-observer ref="formLimitSetting">
                                    <b-form>

                                      <b-row
                                        v-for="key in getKeysLimitSportStep"
                                        :key="key"
                                      >
                                        <AccountSettingSportM8LimitSetting
                                          :is-all-empty="checkisAllEmptyLimitSetting({settingLimit, settingKey})"
                                          :setting-limit="settingLimit"
                                          :init-setting-limit="
                                            getInitSettingLimitWithKey
                                          "
                                          :limit-key="key"
                                          :setting-key="settingKey"
                                          :upline-init-setting-limit="
                                            uplineInitSettingLimit
                                          "
                                          @onUpdateLimitSetting="
                                            onUpdateLimitSetting
                                          "
                                        />
                                      </b-row>
                                    </b-form>
                                  </validation-observer>
                                </b-card>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </b-card-text>
                      <slot />
                    </b-card>
                  </b-col>
                </b-row>
              </b-col>
              <!-- end live -->
            </b-row>
          </b-card-text>
        </fieldset>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import SettingComponentSportM8 from "@/components/settings/accounts-settings/SettingComponentSportM8";
import AccountSettingSportM8Commission from "@/components/settings/accounts-settings/providers/AccountSettingSportM8Commission";
import AccountSettingSportM8LimitSetting from "@/components/settings/accounts-settings/providers/AccountSettingSportM8LimitSetting";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import "@validations";
import store from "@/store";
import get from "lodash/get";

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    SettingComponentSportM8,
    AccountSettingSportM8Commission,
    AccountSettingSportM8LimitSetting,
  },
  props: {
    headerTitle: { type: String, required: true },
    settingKey: { type: String, required: true },
  },
  data() {
    return {
      // disabled by pass used type A only
      allowChooseHdpOuOeType: false,

      commissionMemberKeysSportWithLive: [
        "hdpOuOe",
        "oneTwoDoubleChance",
        "others",
        // "com4",
        // "com5",
        // "com6",
        // "com7",
        // "com8",
        // "com9",
      ],
      commissionMemberKeysSportStep: [
        "com4",
        "com5",
        "com6",
        "com7",
        "com8",
        "com9",
      ],

      commissionAgentKeysSportWithLive: [
        "hdpOuOeA",
        // "hdpOuOeB",
        // "hdpOuOeC",
        // "hdpOuOeD",
        // "hdpOuOeE",
        // "hdpOuOeF",
        "oneTwoDoubleChance",
        "others",
        // "com4",
        // "com5",
        // "com6",
        // "com7",
        // "com8",
        // "com9",
      ],
      commissionAgentKeysSportStep: [
        "com4",
        "com5",
        "com6",
        "com7",
        "com8",
        "com9",
      ],

      optionsCommissionType: [
        { title: "A (HDP/OU/OE)", data: "hdpOuOeA" },
        { title: "B (HDP/OU/OE)", data: "hdpOuOeB" },
        { title: "C (HDP/OU/OE)", data: "hdpOuOeC" },
        { title: "D (HDP/OU/OE)", data: "hdpOuOeD" },
        { title: "E (HDP/OU/OE)", data: "hdpOuOeE" },
        { title: "F (HDP/OU/OE)", data: "hdpOuOeF" },
      ],
    };
  },
  computed: {
    getKeysLimitSportAll() {
      // max1: `Max Bet HDP/OU/OE`,
      // max2: `Max Single Bet Limit Parlay`,
      // max3: `Max Bet Correct Score`,
      // max4: `Max Bet Step`,
      // max5: `Max Bet 1D`,
      // max6: `Max Bet 2D`,
      // max7: `Max Bet 3D`,
      // lim1: `Max Per Match HDP/OU/OE`,
      // lim2: `Mix Parlay Limit Per Combination`,
      // lim3: `Max Per Match Correct Score`,
      // lim4: `Step Limit Per Combination`,

      return [
        'max1',
        'max2',
        'max3',
        'max4',
        'max5',
        'max6',
        'max7',
        'lim1',
        'lim2',
        'lim3',
        'lim4',
      ]
    },
    getKeysLimitSportWithLive() {
      // max1: `Max Bet HDP/OU/OE`,
      // max2: `Max Single Bet Limit Parlay`,
      // max3: `Max Bet Correct Score`,
      // max4: `Max Bet Step`,
      // max5: `Max Bet 1D`,
      // max6: `Max Bet 2D`,
      // max7: `Max Bet 3D`,
      // lim1: `Max Per Match HDP/OU/OE`,
      // lim2: `Mix Parlay Limit Per Combination`,
      // lim3: `Max Per Match Correct Score`,
      // lim4: `Step Limit Per Combination`,

      // max2 // for basketball > limit all
      return ['max1', 'lim1', 'max2']
    },
    getKeysLimitSportStep() {
      // max1: `Max Bet HDP/OU/OE`,
      // max2: `Max Single Bet Limit Parlay`,
      // max3: `Max Bet Correct Score`,
      // max4: `Max Bet Step`,
      // max5: `Max Bet 1D`,
      // max6: `Max Bet 2D`,
      // max7: `Max Bet 3D`,
      // lim1: `Max Per Match HDP/OU/OE`,
      // lim2: `Mix Parlay Limit Per Combination`,
      // lim3: `Max Per Match Correct Score`,
      // lim4: `Step Limit Per Combination`,

      // lim2 // for basketball > limit per match
      return ['max4', 'lim4', 'lim2']
    },

    initCommissionFrontend() {
      return store.getters["accountCreateSetting/getInitCommissionFrontend"];
    },
    // upline setting
    uplineInitSetting() {
      return store.getters["accountCreateSetting/getUplineInitSetting"];
    },
    uplineInitSettingCommission() {
      return store.getters[
        "accountCreateSetting/getUplineInitSettingCommission"
      ];
    },
    uplineInitSettingLimit() {
      return store.getters["accountCreateSetting/getUplineInitSettingLimit"];
    },

    // agent setting
    initSetting() {
      return store.getters["accountCreateSetting/getInitSetting"];
    },
    initSettingCommission() {
      return store.getters["accountCreateSetting/getInitSettingCommission"];
    },
    initSettingLimit() {
      return store.getters["accountCreateSetting/getInitSettingLimit"];
    },
    setting() {
      return store.getters["accountCreateSetting/getSetting"];
    },
    settingCommission() {
      return store.getters["accountCreateSetting/getSettingCommission"];
    },
    settingLimit() {
      return store.getters["accountCreateSetting/getSettingLimit"];
    },
    settingIsMember() {
      return store.getters["accountCreateSetting/getIsMember"];
    },
    settingType() {
      return store.getters["accountCreateSetting/getSettingType"];
    },
    getSettingCommissionWithKey() {
      return get(this.settingCommission, this.settingKey);
    },
    getInitSettingCommissionWithKey() {
      return get(this.initSettingCommission, this.settingKey);
    },
    getInitSettingLimitWithKey() {
      return get(this.initSettingLimit, this.settingKey)
    },

    memberHdpOuOeType() {
      return this.getSettingCommissionWithKey.hdpOuOeType ?? "hdpOuOeA";
    },
    hdpOuOeInit() {
      if (this.settingIsMember) {
        return this.getInitSettingCommissionWithKey?.hdpOuOe ?? 0;
      }
      return undefined;
    },
    hdpOuOeInitNew() {
      if (this.settingIsMember) {
        return this.getInitSettingCommissionWithKey?.hdpOuOeA ?? 0;
      }
      return undefined;
    },
  },
  mounted() {
    if (this.settingIsMember) {
      store.commit("accountCreateSetting/UPDATE_SETTING_COMMISSION_BY_KEY", {
        key: this.settingKey,
        data: {
          hdpOuOe:
            this.settingType === "NEW" ? this.hdpOuOeInitNew : this.hdpOuOeInit,
          hdpOuOeType: this.memberHdpOuOeType,
        },
      });
    }

    if (this.settingType === "NEW") {
      store.commit("accountCreateSetting/UPDATE_SETTING_COMMISSION_BY_KEY", {
        key: this.settingKey,
        data: {
          com4: 0,
          com5: 0,
          com6: 0,
          com7: 0,
          com8: 0,
          com9: 0,
        },
      });
    }

    this.updateSettingLimitNotDisplay()
  },
  methods: {
    updateSettingLimitNotDisplay() {
      const settingKeysAll = this.getKeysLimitSportAll

      const settingKeysLive = this.getKeysLimitSportWithLive
      const settingKeysStep = this.getKeysLimitSportStep

      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < settingKeysAll.length; index++) {
        const _settingKey = settingKeysAll[index];

        // settingLimit
        if (!settingKeysLive.includes(_settingKey)) {
          const uplineLimitByKey = get(this.uplineInitSettingLimit, `${this.settingKey}.${_settingKey}`, 0)

          store.commit("accountCreateSetting/UPDATE_SETTING_LIMIT_BY_KEY", {
            key: this.settingKey,
            data: {
              // update from agent
              // [_settingKey]: uplineLimitByKey,

              // now always 0
              [_settingKey]: 0,
            },
          });
        }
      }
    },
    get,

    checkisAllEmptyLimitSetting({ settingLimit, settingKey }) {
      const limitAllSettings = get(settingLimit, settingKey)
      // const isAllEmpty = !limitAllSettings || (typeof limitAllSettings === 'object' && Object.values(limitAllSettings).every(val => !val))
      const isAllEmpty = !limitAllSettings || (typeof limitAllSettings === 'object' && Object.values(limitAllSettings).every(val => Number.isNaN(+val) || Number(+val) < 0))

      return Boolean(isAllEmpty)
    },

    onMemberUpdateCommissionType(val) {
      store.commit("accountCreateSetting/UPDATE_SETTING_COMMISSION_BY_KEY", {
        key: this.settingKey,
        data: {
          hdpOuOeType: val,
        },
      });
    },

    onUpdateCommission({ val, commissionKey }) {
      store.commit("accountCreateSetting/UPDATE_SETTING_COMMISSION_BY_KEY", {
        key: this.settingKey,
        data: {
          [commissionKey]: val,
        },
      });
    },

    onUpdateLimitSetting({ val, settingKey, limitKey }) {
      store.commit("accountCreateSetting/UPDATE_SETTING_LIMIT_BY_KEY", {
        key: this.settingKey,
        data: {
          [limitKey]: val,
        },
      })
    },
  },
};
</script>

<style>
</style>
