<template>
  <fieldset
    :disabled="!uplineStatusIsEnable"
    :style="styleStatus"
  >
    <div class="pt-1">
      <b-row>
        <b-col cols="12">
          <b-form-checkbox
            v-if="!isHideCheckboxEnabled"
            v-model="settingIsEnable"
            class="custom-control-success"
            switch
            :disabled="!uplineStatusIsEnable"
          >
            <span class="h5 font-weight-bolder">{{ $t('Disable / Enable') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row align="start">
        <b-col
          cols="12"
        >
          <b-badge
            variant="light-primary"
          >
            {{ headerTitle }}
          </b-badge>
        </b-col>
      </b-row>

      <b-row align="start">
        <b-col
          cols="12"
        >
          {{ $t('Min') }}
          <b-badge
            variant="light-danger"
          >
            {{ settingForceMin }}%
          </b-badge>
        </b-col>
        <b-col
          cols="12"
        >
          {{ $t('Max') }}
          <b-badge
            variant="light-success"
          >
            {{ settingMax }}%
          </b-badge>
        </b-col>
      </b-row>

      <b-row class="align-items-center pb-1">
        <b-col cols="4">
          {{ $t('Min PT') }} (%)
        </b-col>
        <b-col>
          <SelectPercentage
            :value.sync="uplinePTValue"
            :disabled="!uplineStatusIsEnable"
            :min="0"
            :max="settingMax"
            @onUpdate="uplinePTValueOnUpdate"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!settingIsMember"
        class="align-items-center pb-1"
      >
        <b-col cols="4">
          {{ $t('Give PT') }} (%)
        </b-col>
        <b-col>
          <SelectPercentage
            :value.sync="sharePTValue"
            :disabled="!uplineStatusIsEnable"
            :min="0"
            :max="settingMax"
            @onUpdate="sharePTValueOnUpdate"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!settingIsMember"
        class="align-items-center pb-1"
      >
        <b-col cols="4">
          {{ $t('Forced PT') }} (%)
        </b-col>
        <b-col>
          <SelectPercentage
            :value.sync="forcePTValue"
            :disabled="!uplineStatusIsEnable"
            :min="0"
            :max="+sharePTValue"
            @onUpdate="forcePTValueOnUpdate"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!settingIsMember"
        class="align-items-center pb-1"
      >
        <b-col cols="4">
          {{ $t('Take Remaining') }}
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="saveShareValue"
            class="custom-control-success"
            switch
            :disabled="!uplineStatusIsEnable"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="listsShowLimit.includes(settingKey) && listsLimit(settingKey).length > 0"
      >
        <b-col cols="4">
          {{ $t('Limit Bet') }}
        </b-col>
        <b-col>
          <!-- <b-form-group>
                  <b-form-radio-group
                    v-model="limit"
                    :options="listsLimit(settingKey)"
                    stacked
                  />
                </b-form-group> -->

          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio-group
              v-model="settingLimit"
              :aria-describedby="ariaDescribedby"
              :name="`${settingKey}-radio-limit`"
            >
              <b-form-radio
                v-for="listLimit in listsLimit(settingKey)"
                :key="`${settingKey}-${listLimit.text}`"
                :value="listLimit.value"
                class="setting__limit__radio"
                :class="[Number(settingLimit) >= Number(listLimit.value) ? 'checked' : '']"
                :disabled="Number(listLimit.value) > Number(uplineSettingLimit)"
              >
                {{ listLimit.text }}
                <b-icon
                  v-if="Number(settingLimit) >= Number(listLimit.value)"
                  icon="check-circle"
                  variant="success"
                />
                <b-icon
                  v-else
                  icon="x-circle"
                  variant="danger"
                />
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

        </b-col>

      </b-row>

    </div>

    <slot />

  </fieldset>

</template>

<script>
import SelectPercentage from '@/components/settings/SelectPercentage'
import get from 'lodash/get'
import store from '@/store'

export default {
  components: {
    SelectPercentage,
  },
  props: {
    headerTitle: { type: String, required: true },
    settingKey: { type: String, required: true },
    settingKeyEnabled: { type: String, required: false, default: null },
    isHideCheckboxEnabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
    //   settingKey: 'game.slot',
      limitOptions: {
        'casino.sexy': [
          { text: "Limit A Min = 10 , Max = 2000", value: '0' },
          { text: "Limit B Min = 50 , Max = 5000", value: '1' },
          { text: "Limit C Min = 200 , Max = 50000", value: '2' },
          { text: "Limit D Min = 500 , Max = 100000", value: '3' },
          { text: "Limit E Min = 2500 , Max = 200000", value: '4' },
        ],
        'casino.dreamgame': [
          { text: "Limit A Min = 50 , Max = 10000", value: '0' },
          { text: "Limit B Min = 100 , Max = 10000", value: '1' },
          { text: "Limit C Min = 100 , Max = 50000", value: '2' },
          { text: "Limit D Min = 100 , Max = 100000", value: '3' },
          { text: "Limit E Min = 100 , Max = 250000", value: '4' },
        ],
        'casino.prettygaming': [
          { text: "Limit A Min = 5 , Max = 2000", value: '0' },
          { text: "Limit B Min = 100 , Max = 4000", value: '1' },
          { text: "Limit C Min = 1000 , Max = 40000", value: '2' },
          { text: "Limit D Min = 3000 , Max = 120000", value: '3' },
          { text: "Limit E Min = 5000 , Max = 200000", value: '4' },
        ],
        'casino.sa': [
          { text: 'Limit A Min = 10 , Max = 1000', value: '0' },
          { text: 'Limit B Min = 50 , Max = 5000', value: '1' },
          { text: 'Limit C Min = 100 , Max = 10000', value: '2' },
          { text: 'Limit D Min = 500 , Max = 50000 ', value: '3' },
          { text: 'Limit E Min = 2000 , Max = 200000', value: '4' },
        ],
        'casino.allbet': [
          { text: 'Limit A Min = 20 , Max = 10000', value: '0' },
          { text: 'Limit B Min = 50 , Max = 5000', value: '1' },
          { text: 'Limit C Min = 100 , Max = 10000', value: '2' },
        ],
        'casino.ebet': [
          { text: 'Limit A Min = 5 , Max = 5000', value: '0' },
          { text: 'Limit B Min = 10 , Max = 10000', value: '1' },
          { text: 'Limit C Min = 50 , Max = 50000', value: '2' },
          { text: 'Limit D Min = 100 , Max = 100000', value: '3' },
          { text: 'Limit E Min = 1000 , Max = 300000', value: '4' },
        ],
        'casino.casino_pragmaticplay': [
          { text: 'Limit A Min = 2 , Max = 100000', value: '0' },
        ],
        'casino.casino_cq9': [
          { text: 'Limit A Min = 2 , Max = 100000', value: '0' },
        ],

        // sport
        'sport.xcockfight': [
          { text: "Limit A Min = 20 , Max = 2000", value: '0' },
          // { text: "Limit B Min = 100 , Max = 5000", value: '1' },
          // { text: "Limit C Min = 200 , Max = 10000", value: '2' },
          // { text: "Limit D Min = 300 , Max = 15000", value: '3' },
          // { text: "Limit E Min = 500 , Max = 15000", value: '4' },
        ],
      },
      listsShowLimit: [
        'casino.sexy',
        'casino.dreamgame',
        'casino.prettygaming',
        'casino.sa',
        'casino.allbet',
        'casino.ebet',
        'casino.casino_pragmaticplay',
        'casino.casino_cq9',

        // sport
        'sport.xcockfight',
      ],
    }
  },
  computed: {
    // upline
    uplineStatus() {
      return store.getters['accountCreateSetting/getUplineStatus']
    },
    uplineStatusIsEnable() {
      const settingKey = this.settingKeyEnabled || this.settingKey
      return get(this.uplineStatus, `setting.${settingKey}.isEnable`, false)
    },
    // end upline

    // agent setting
    setting() {
      return store.getters['accountCreateSetting/getSetting']
    },
    uplineSetting() {
      return store.getters['accountCreateSetting/getUplineSetting']
    },
    settingType() {
      return store.getters['accountCreateSetting/getSettingType']
    },
    settingMax() {
      return parseFloat(get(this.setting, `${this.settingKey}.max`, 0))
    },
    settingForceMin() {
      return parseFloat(get(this.setting, `${this.settingKey}.min`, 0))
      // return parseFloat(get(this.setting, `${this.settingKey}.force`, 0))
    },
    settingIsEnable: {
      get() {
        if (this.settingKeyEnabled) {
          return Boolean(get(this.setting, `${this.settingKeyEnabled}.isEnable`, false))
        }
        return Boolean(get(this.setting, `${this.settingKey}.isEnable`, false))
      },
      set(val) {
        store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
          key: this.settingKey,
          data: {
            isEnable: Boolean(val),
          },
        })
      },

    },

    // upline setting
    uplineSettingLimit() {
      return String(get(this.uplineSetting, `${this.settingKey}.settingLimit`, '0'))
    },
    // end upline setting

    // other
    settingIsMember() {
      return store.getters['accountCreateSetting/getIsMember']
    },
    styleStatus() {
      if (this.uplineStatusIsEnable) {
        return {}
      }
      return {
        opacity: "0.4",
      }
    },
    uplinePTValue: {
      get() {
        return get(this.setting, `${this.settingKey}.upline`) || '0'
      },
      set(val) {
        if (!val) {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              upline: '0',
            },
          })
        } else {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              upline: parseFloat(val.value) || '0',
            },
          })
        }
      },
    },
    sharePTValue: {
      get() {
        return get(this.setting, `${this.settingKey}.share`) || '0'
      },
      set(val) {
        if (!val) {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              share: '0',
            },
          })
        } else {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              share: parseFloat(val.value) || '0',
            },
          })
        }
      },
    },
    forcePTValue: {
      get() {
        return get(this.setting, `${this.settingKey}.force`) || '0'
      },
      set(val) {
        if (!val) {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              force: '0',
            },
          })
        } else {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              force: parseFloat(val.value) || '0',
            },
          })
        }
      },
    },
    saveShareValue: {
      get() {
        return Boolean(get(this.setting, `${this.settingKey}.saveShare`, true))
      },
      set(val) {
        store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
          key: this.settingKey,
          data: {
            saveShare: Boolean(val),
          },
        })
      },

    },
    settingLimit: {
      get() {
        return String(get(this.setting, `${this.settingKey}.settingLimit`, '0'))
      },
      set(val) {
        if (!val) {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              settingLimit: '0',
            },
          })
        } else {
          store.commit('accountCreateSetting/UPDATE_SETTING_BY_KEY', {
            key: this.settingKey,
            data: {
              settingLimit: String(val),
            },
          })
        }
      },
    },
  },
  mounted() {
  },
  methods: {
    get,
    getHeaderTitle(title) {
      if (title === 'SLOTGROUPQT') {
        const slotgroupqt = [
          'RLX', // relaxgaming
          'BPG', // blueprintgaming
          'QS', // quickspin
          '1x2', // 1x2gaming
          'NLC', // nolimitcity
          // 'PNG', // playngo
        ]
        return slotgroupqt.join(', ').toUpperCase()
      } if (title === 'SLOTGROUPSAPI') {
        const slotgroupsapi = [
          'ACE333', // ace333
          'SPINIX', // spinix
          'KISS918', // kiss918
          'NETENT',
          'REDTIGER',
          'NEXTSPIN',
          'NINJA',
          'ADVANT',
          'ASKMEPLAY',
          'BOLEBIT',
          'WMSLOT',
        ]
        return slotgroupsapi.join(', ').toUpperCase()
      } if (title === 'CASINOGROUPSAPI') {
        const casinogroupsapi = [
          'WECASINO',
          'AGGAME',
          'BETGAME',
          'MICRO_LIVECASINO',
        ]
        return casinogroupsapi.join(', ').toUpperCase()
      }

      return title
    },
    // limit
    listsLimit(settingKey) {
      const lists = get(this.limitOptions, settingKey)
      if (lists && Array.isArray(lists)) {
        return lists
      }
      return []
    },
    uplinePTValueOnUpdate(val) {
      this.sharePTValue = { value: this.settingMax - parseFloat(val.value) }

      const totalSharePTValue = parseFloat(this.sharePTValue)

      const currentForcePTValue = parseFloat(this.forcePTValue)
      if (currentForcePTValue > totalSharePTValue) {
        this.forcePTValue = { value: parseFloat(totalSharePTValue) }
      }
    },
    sharePTValueOnUpdate(val) {
      const totalSharePTValue = parseFloat(val.value)

      // disabled same as askmebet
      // this.uplinePTValue = { value: this.settingMax - totalSharePTValue }

      // const currentForcePTValue = parseFloat(this.forcePTValue)
      // if (currentForcePTValue > totalSharePTValue) {
      //   this.forcePTValue = { value: parseFloat(totalSharePTValue) }
      // }
      this.forcePTValue = { value: parseFloat(totalSharePTValue) }
    },
    forcePTValueOnUpdate(val) {
    },
  },
}
</script>

<style lang="scss" scoped>
.card__setting {
  height: 95%;
}
</style>
