<template>

  <b-card no-body>
    <b-row
      align-v="stretch"
      class="setting__lists"
    >
      <b-col
        v-for="setting in settings"
        :key="setting.title"
        cols="12"
        :sm="setting.size"
      >
        <!-- <SettingComponent
          :header-title="setting.title"
          :setting-key="setting.key"
        /> -->
        <component
          :is="setting.component"
          :header-title="setting.title"
          :setting-key="setting.key"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import SettingComponent from '@/components/settings/accounts-settings/SettingComponent'
import AccountSettingSportM8 from '@/components/settings/accounts-settings/providers/AccountSettingSportM8.vue'

export default {
  components: {
    SettingComponent,
    AccountSettingSportM8,
  },
  data() {
    return {
      // settings: [
      //   { title: 'M8', key: 'sport.m8' },
      //   { title: 'XCOCKFIGHT', key: 'sport.xcockfight' },
      // ],
    }
  },
  computed: {
    settings() {
      return [
        {
          title: 'SBO', key: 'sport.sbo', component: 'SettingComponent', size: 12,
        },
        {
          title: 'M8', key: 'sport.m8', component: 'AccountSettingSportM8', size: 12,
        },
        {
          title: 'XCOCKFIGHT', key: 'sport.xcockfight', component: 'SettingComponent', size: 6,
        },
      ]
    },
  },
}
</script>

<style>

</style>
