<template>
  <b-col
    xs="12"
  >
    <b-row
      class="align-items-center p-1 no-gutters"
    >
      <b-col cols="4">
        {{ getTextByKey(commissionKey) }} <b>{{ getInitValueByKey(commissionKey) }}</b>
      </b-col>
      <b-col>
        <v-select
          :value="get(settingCommission, `${settingKey}.${commissionKey}`)"
          label="title"
          :options="optionsData"
          :clearable="false"
          :reduce="option => option.data"
          @input="(val) => $emit('onUpdateCommission', {val, commissionKey})"
        />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { get } from 'lodash'

export default {
  props: {
    initCommissionFrontend: { type: Object, required: true },
    settingCommission: { type: Object, required: true },
    initSettingCommission: { type: Object, required: true },
    commissionKey: { type: String, required: true },
    settingKey: { type: String, required: true },
    hdpOuOeInit: { type: [String, Number], required: false, default: undefined },
    settingIsMember: { type: Boolean, required: true },
  },
  computed: {
    optionsData() {
      const commissionStepDefault = get(this.initCommissionFrontend, `${this.settingKey}.stepMax`)
      if (this.isCommissionKeyFromStep(this.commissionKey)) {
        return this.buildOptionsSelect(this.buildCommissionStepList(commissionStepDefault[this.commissionKey]))
      }

      if (this.hdpOuOeInit) {
        return this.buildOptionsSelect(this.buildCommissionHdpList(this.hdpOuOeInit))
      }

      let val = get(this.initSettingCommission, this.commissionKey)
      if (!val) {
        val = get(this.initCommissionFrontend, `${this.settingKey}.${this.commissionKey}`)
      }
      return this.buildOptionsSelect(this.buildCommissionHdpList(val))
    },
  },
  methods: {
    get,
    isCommissionKeyFromStep(commissionKey) {
      return [
        'com4',
        'com5',
        'com6',
        'com7',
        'com8',
        'com9',
      ].some(key => key === commissionKey)
    },
    buildCommissionStepList(max) {
      const results = [0]
      let val = 0
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (val >= max) {
          break
        }
        val += 1
        results.push(val)
      }

      return results
    },
    buildCommissionHdpList(val) {
      if (!val) {
        return []
      }
      const input = []
      for (let loop = val + 0.6, i = 0; 10 * loop >= i; i += 0.5) { input.push(i / 10) }
      return input
    },
    buildOptionsSelect(lists) {
      return lists.map((val, index) => ({ title: `${val}%`, data: val }))
    },
    getTextByKey(key) {
      const lists = {
        hdpOuOe: `(HDP/OU/OE)`,
        hdpOuOeA: `A (HDP/OU/OE)`,
        hdpOuOeB: `B (HDP/OU/OE)`,
        hdpOuOeC: `C (HDP/OU/OE)`,
        hdpOuOeD: `D (HDP/OU/OE)`,
        hdpOuOeE: `E (HDP/OU/OE)`,
        hdpOuOeF: `F (HDP/OU/OE)`,
        oneTwoDoubleChance: `1X2/Double Chance`,
        others: `Others`,
        com4: `2 match`,
        com5: `3-4 match`,
        com6: `5-6 match`,
        com7: `7-8 match`,
        com8: `9-10 match`,
        com9: `11-12 match`,
      }
      return get(lists, key, '')
    },
    getInitValueByKey(key) {
      if (this.isCommissionKeyFromStep(key)) {
        return ''
      }

      if (key === 'hdpOuOe') {
        return this.hdpOuOeInit
      }
      return get(this.initSettingCommission, this.commissionKey, '')
    },
  },
}
</script>

<style>

</style>
