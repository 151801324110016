<template>
  <b-form-input
    v-bind="$attrs"
    :formatter="formatter"
    :value="_value"
    lazy-formatter
    @focus="onfocus"
    @blur="onblur"
    @input="onUpdateValue"
  />
</template>

<script>
import { DecimalFormatCustomFix } from '@/utils/number'

export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      onBlurInput: true,
    }
  },
  computed: {
    _value() {
      return this.formatter(this.value)
    },
  },
  mounted() {
  },
  methods: {
    formatter(value) {
      if (!this.onBlurInput) {
        return value
      }
      value = Number(String(value).replace(/,/g, ''))
      if (!value) {
        return '0'
      }
      return DecimalFormatCustomFix(value, 0)
    },
    onblur() {
      this.onBlurInput = true
    },
    onfocus() {
      this.onBlurInput = false
      const tempValue = this.value
      this.$emit('updateValue', '')
      setTimeout(() => {
        this.$emit('updateValue', Number(String(tempValue).replace(/,/g, '')))
      }, 1)
    },
    onUpdateValue(val) {
      this.$emit('updateValue', Number(String(val).replace(/,/g, '')))
    },
  },
}
</script>

<style>

</style>
