<template>
  <b-tabs
    v-model="activeTab"
    pills
  >
    <b-tab
      v-for="tab in tabs"
      :key="tab.title"

      :title="tab.title"
    >
      <component :is="tab.component" />
    </b-tab>
  </b-tabs>
</template>

<script>
import AccountSettingSlot from './accounts-settings/AccountSettingSlot'
import AccountSettingCasino from './accounts-settings/AccountSettingCasino'
import AccountSettingSport from './accounts-settings/AccountSettingSport'
import AccountSettingEsport from './accounts-settings/AccountSettingEsport'
import AccountSettingLottery from './accounts-settings/AccountSettingLottery'

export default {
  components: {
    AccountSettingSlot,
    AccountSettingCasino,
    AccountSettingSport,
    AccountSettingEsport,
    AccountSettingLottery,
  },
  data() {
    return {
      tabs: [
        { title: 'SLOT', component: 'AccountSettingSlot' },
        { title: 'CASINO', component: 'AccountSettingCasino' },
        { title: 'SPORT', component: 'AccountSettingSport' },
        { title: 'ESPORT', component: 'AccountSettingEsport' },
        { title: 'LOTTERY', component: 'AccountSettingLottery' },
      ],
      activeTab: process.env.NODE_ENV === 'development' ? 2 : 0,
    }
  },
}
</script>
