<template>

  <b-card no-body>
    <b-row
      align-v="stretch"
      class="setting__lists"
    >
      <b-col
        v-for="setting in settings"
        :key="setting.title"
        cols="12"
        sm="6"
      >
        <SettingComponent
          :header-title="setting.title"
          :setting-key="setting.key"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import SettingComponent from '@/components/settings/accounts-settings/SettingComponent'

export default {
  components: {
    SettingComponent,
  },
  data() {
    return {
      settings: [
        { title: 'XLOTTERY', key: 'lottery.xlottery' },
      ],
    }
  },
}
</script>

<style>

</style>
