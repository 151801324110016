<template>
  <b-col
    xs="12"
  >
    <b-row
      class="align-items-center pl-1 pr-1 no-gutters"
    >
      <b-col cols="5">
        {{ getTextByKey(limitKey) }}
      </b-col>
      <b-col>
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            :name="getTextByKey(limitKey)"
            rules="required|numeric|min_value:10"
          >
            <InputNumberFormat
              :value="getValueSettingLimit"
              :state="errors.length > 0 ? false:null"
              autocomplete="off"
              @updateValue="onUpdateValue"
            />
            <small>Max = {{ getMaxLimitByKey(limitKey) }}</small>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  ValidationProvider, ValidationObserver,
} from 'vee-validate'
import { DecimalFormatCustomFix } from '@/utils/number'
import InputNumberFormat from '@/components/input-number-format'
import { get } from 'lodash'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    InputNumberFormat,
  },
  props: {
    // initLimit: { type: Object, required: true },
    // limitSetting: { type: Object, required: true },
    // limitSettingKey: { type: String, required: true },

    settingLimit: { type: Object, required: true },
    initSettingLimit: { type: Object, required: true },
    limitKey: { type: String, required: true },
    settingKey: { type: String, required: true },
    uplineInitSettingLimit: { type: Object, required: true },
    isAllEmpty: { type: Boolean, required: false },
  },
  data() {
    return {
      init: false,
    }
  },
  computed: {
    getValueSettingLimit() {
      let limitByKey = get(this.settingLimit, `${this.settingKey}.${this.limitKey}`)
      const isAllEmpty = !this.init && this.isAllEmpty

      const maxLimitFromUpline = this.getUplineSettingLimitByKey
      let forceUpdate = false
      if (Number.isNaN(Number(limitByKey))) {
        limitByKey = maxLimitFromUpline
        forceUpdate = true
      } else if (Number(limitByKey) > maxLimitFromUpline || isAllEmpty) {
        limitByKey = maxLimitFromUpline
        forceUpdate = true
      }

      if (forceUpdate) {
        this.onUpdateValue(limitByKey)
      }

      return limitByKey
    },
    getUplineSettingLimitByKey() {
      return get(this.uplineInitSettingLimit, `${this.settingKey}.${this.limitKey}`)
    },
  },
  mounted() {
    this.init = true
  },
  methods: {
    get,
    DecimalFormatCustomFix,
    onUpdateValue(val) {
      this.$emit('onUpdateLimitSetting', {
        val,
        settingKey: this.settingKey,
        limitKey: this.limitKey,
      })
    },
    getMaxLimitByKey(limitKey) {
      return DecimalFormatCustomFix(this.getUplineSettingLimitByKey, 0)
    },
    getTextByKey(key) {
      const lists = {
        max1: `Max Bet HDP/OU/OE`,
        max2: `Max Single Bet Limit Parlay`,
        max3: `Max Bet Correct Score`,
        max4: `Max Bet Step`,
        max5: `Max Bet 1D`,
        max6: `Max Bet 2D`,
        max7: `Max Bet 3D`,
        lim1: `Max Per Match HDP/OU/OE`,
        lim2: `Mix Parlay Limit Per Combination`,
        lim3: `Max Per Match Correct Score`,
        lim4: `Step Limit Per Combination`,
      }
      return get(lists, key, '')
    },
  },
}
</script>

<style>

</style>
